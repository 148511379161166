import { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Alert, Checkbox, FormControlLabel, TextField } from '@mui/material';
import * as yup from 'yup';

import { Button } from '../../../components/button/Button';
import IFormError from '../../../interfaces/IFormError';
import { useValidateSchema } from '../../../services/useValidateSchema';
import { useHubSpotService } from '../../../services/useHubSpotService';
import { useAuth } from '../../../hooks/auth';

interface IProps {
  orderId: string;
  customerId: string;
  orderTypeId: number;
  open: boolean;
  deny: () => void;
  cloneOrder: (id: string, keepItems: boolean, hubSpotId: string) => void;
}

interface IFormErrorResult {
  errors: IFormError | null;
  isValid: boolean;
}

const OrderCloneDialog = ({ orderId, customerId, orderTypeId, open, cloneOrder, deny }: IProps) => {
  const validation = useValidateSchema();
  const { validateHubSpotDealId } = useHubSpotService();
  const { orderParams, state } = useAuth();

  const [keepItems, setKeepItems] = useState(true);
  const [hubSpotId, setHubSpotId] = useState('');
  const [errorsHubSport, setErrorsHubSport] = useState<IFormError | null>();
  const [hubSpotDealIdIsValid, setHubSpotDealIdIsValid] = useState(false);
  const [hubSpotMessageError, setHubSpotMessageError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => {
    setKeepItems(true);
    setHubSpotId('');
    setErrorsHubSport({});
    setHubSpotDealIdIsValid(false);
    setHubSpotMessageError('');
    deny();
  };

  const handleCloneOrder = (orderId: string, keepItems: boolean, hubSpotId: string) => {
    cloneOrder(orderId, keepItems, hubSpotId);
  };

  const handleValidateHubSpotDealId = useCallback(async () => {
    let resultHubSpot: IFormErrorResult | null = null;
    setErrorsHubSport(null);

    const data = { hubSpotId, orderTypeId };

    const schemaHubSpot = yup.object({
      hubSpotId: yup.string().required('Campo obrigatório').min(5, 'Campo obrigatório.'),
    });

    resultHubSpot = await validation.validate(schemaHubSpot, data || {});

    if (!resultHubSpot.isValid) {
      setErrorsHubSport(resultHubSpot.errors);
      return;
    }

    setLoading(true);
    await validateHubSpotDealId(hubSpotId, orderTypeId || 1, state.seller?.id || '')
      .then(response => {
        if (customerId !== response.customer.id) {
          setHubSpotMessageError('O cliente da negociação deve ser o mesmo que o do pedido que deseja duplicar');
          return;
        }
        setHubSpotDealIdIsValid(true);
      })
      .catch(error => {
        setHubSpotMessageError(error.message || 'Erro ao consultar pedido no HubSpot, contate o suporte.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [hubSpotId, orderTypeId]);

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: '250px' }}>
        {hubSpotDealIdIsValid || !orderParams?.integrateHubSpot ? (
          <>
            <DialogContentText style={{ marginBottom: '8px' }}>
              <strong style={{ fontSize: '16px' }}>Deseja realmente duplicar o pedido?</strong>
            </DialogContentText>

            <FormControlLabel
              control={
                <Checkbox
                  checked={keepItems}
                  onClick={() => {
                    setKeepItems(!keepItems);
                  }}
                />
              }
              label="Manter os itens"
            />

            <DialogActions>
              <Button
                onClick={() => {
                  setHubSpotDealIdIsValid(false);
                }}
                color="error"
                variant="contained"
              >
                Voltar
              </Button>
              <Button onClick={() => handleCloneOrder(orderId, keepItems, hubSpotId)} autoFocus variant="contained">
                Duplicar
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContentText style={{ marginBottom: '8px' }}>
              <strong style={{ fontSize: '16px' }}>Informe o ID da Negociação no HubSpot</strong>
            </DialogContentText>

            <TextField
              sx={{ width: '100%', marginTop: '16px', marginBottom: '12px' }}
              size="small"
              label="ID Negociação HubSpot"
              value={hubSpotId}
              onChange={e => setHubSpotId(e.target.value)}
              autoComplete="off"
              error={Boolean(errorsHubSport?.hubSpotId)}
              helperText={errorsHubSport?.hubSpotId}
            />

            {hubSpotMessageError && <Alert severity="error">{hubSpotMessageError}</Alert>}

            <DialogActions>
              <Button onClick={handleCloseDialog} color="error" variant="contained" disabled={loading}>
                Cancelar
              </Button>
              <Button
                onClick={handleValidateHubSpotDealId}
                autoFocus
                variant="contained"
                disabled={loading}
                loading={loading}
              >
                Validar
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderCloneDialog;
