import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { AiTwotoneEdit } from 'react-icons/ai';
import { FiTrash, FiPlusCircle } from 'react-icons/fi';
import { Button } from '../../components/button/Button';

import {
  TableBodyCard,
  TableCard,
  TableCardRow,
  TableItems,
  TableCellCustom,
  TableCardTitle,
  TableCardDescription,
  IconButtonAdd,
  TotalsContainer,
  TotalsValues,
  OrderItemListContainer,
  ButtonSaveContainer,
} from './OrderItemList.style';
import { IOrderItem } from '../../interfaces/IOrderItem';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import { useOrderItemService } from '../../services/useOrderItemService';
import { OrdemItemEdit } from './OrderItemEdit';
import { useToastr } from '../../hooks/useToastr';
import SocketioService from '../../SocketIoService';
import { useAuth } from '../../hooks/auth';
import { IOrder } from '../../interfaces/IOrder';
import { useOrderService } from '../../services/useOrderService';
import IOrderItemListProps from './interfaces/IOrderItemListProps';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { NumericFormat } from 'react-number-format';
import { useProductService } from '../../services/useProductService';
import { IProduct } from '../../interfaces/IProduct';

interface IParams {
  itemsUpdated?(): void;
}

const initialItem: IOrderItem = {
  sequence: 0,
  quantity: 0,
  price: 0,
  freightValue: 0,
  discountFTZ: 0,
  IPI_Percent: 0,
  IPI_Value: 0,
  ICMSST_Value: 0,
  ICMS_Percent: 0,
  amount: 0,
  offerPercentage: 0,
  offerValue: 0,
  errorMessage: null,
  product: {
    sku: '',
    derivation: '',
  },
};

const OrderItemList = forwardRef<IOrderItemListProps, IParams>((props, ref) => {
  const [items, setItems] = useState<IOrderItem[]>([]);
  const [itemId, setItemId] = useState<string | null>(null);

  const [perPage] = useState(200);
  const [currentPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('sequence');
  const [loading, setLoading] = useState(false);
  const [loadingSkus, setLoadingSkus] = useState(false);

  const [skus, setSkus] = useState<string[]>([]);
  const [derivation, setDerivation] = useState<string>();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [quantity, setQuantity] = useState('');
  const [sku, setSku] = useState<string>();
  const [offerPercentage, setOfferPercentage] = useState('');
  const [additionPercentage, setAdditionPercentage] = useState('');
  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false);
  const [userCommandedToSave, setUserCommandToSave] = useState(false);

  const [orderItem, setOrderItem] = useState<IOrderItem>();

  const [openDialog, setOpenDialog] = useState(false);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);

  const params = useParams();
  const location = useLocation();

  const [isSteper] = useState(location.pathname.split('/').indexOf('steper') !== -1);

  const [orderId] = useState(params.id);

  const { listItemsByOrderId } = useOrderItemService();
  const orderItemService = useOrderItemService();
  const orderService = useOrderService();
  const productService = useProductService();
  const toastr = useToastr();
  const navigate = useNavigate();

  const socketIoService = new SocketioService();

  const { state: user } = useAuth();

  useImperativeHandle(ref, () => ({
    listOrder: handleLoadOrder,
    async finish() {
      const thereAreItemsWithErrors = items.filter(item => item.status?.id !== 2)?.length !== 0;
      const thereAreItems = items?.length !== 0;

      if (thereAreItemsWithErrors) {
        toastr.error('Existem itens com erros de cálculo.');
        return;
      }
      if (!thereAreItems) {
        toastr.error('Não é possível finalizar pedido sem itens.');
        return;
      }
      navigate(`/order/${params.id}/edit`);
    },

    addItem() {
      handleToEdit(null);
    },

    listItems: handleListItems,
  }));

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
    if (openDialog) {
      handleListItems();
      setItemId(null);
    }
  };

  const handleRemoveIconClicked = (id: string) => {
    setItemId(id);
    setIsRemoveDialogOpen(true);
  };

  const handleDenyToRemove = () => {
    setItemId(null);
    setIsRemoveDialogOpen(false);
  };

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const formatCurrencyNumber = (value: number, precision: number | undefined): string => {
    return new Intl.NumberFormat('pt-br', { minimumFractionDigits: precision || 0 }).format(value);
  };

  const isEditionDisabled = () => {
    return order?.orderStatus?.id !== 1 || loading || Number(order?.orderNumber) > 0;
  };

  const clearNotSavedItem = () => {
    setItems(current => {
      return current.filter(i => !!i.id);
    });
    setSku('');
    setDerivation('');
    setQuantity('');
    setOfferPercentage('');
    setAdditionPercentage('');
  };

  const setNewItemRow = () => {
    setSku('');
    setQuantity('');
    setDerivation('');
    setOfferPercentage('');
    setAdditionPercentage('');
    const newItems = [...items];
    const lastSequence = items[items.length - 1]?.sequence;
    const newSequence = lastSequence ? lastSequence + 1 : 0;
    newItems.push({ ...initialItem, sequence: newSequence });
    setItems(newItems);
    setOrderItem({ ...initialItem });
    setEditableRow(newSequence);
    setTimeout(() => {
      const element = document.getElementById(`edt-sku-${newSequence}`);
      element?.focus();
    }, 100);
  };

  const handleLoadOrder = async (): Promise<void> => {
    await orderService
      .listOrder(`${orderId}`, true)
      .then(order => {
        setOrder(order);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveItem = async (): Promise<void> => {
    setLoading(true);
    setIsRemoveDialogOpen(false);
    orderItemService
      .remove(`${itemId}`)
      .then(async () => {
        props.itemsUpdated?.();
        setIsRemoveDialogOpen(false);
        toastr.success('Item excluído com sucesso!');
        await handleListItems();
        await handleLoadOrder();
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleToEdit = async (id: string | null) => {
    setItemId(id);
    handleCloseDialog();
  };

  const handleListSkus = async (filter = '') => {
    const query = `perPage=1000&currentPage=1&orderBy=sku&orderDirection=ASC&sku=${filter}`;
    setLoadingSkus(true);
    await productService
      .listProductsSkus(query)
      .then(result => {
        setSkus(result.data);
      })
      .catch(error => {
        toastr.error(`Ocorreu um erro inesperado ao listar os produtos: ${error.message}`);
      })
      .finally(() => {
        setLoadingSkus(false);
      });
  };

  const handleListProducts = async () => {
    if (sku) {
      return await productService
        .listProductsBySku(`${sku}`)
        .then(result => {
          const prd = result?.map(r => {
            if (!r.derivation || r.derivation === ' ') {
              r.derivation = 'SEM DERIVAÇÃO';
            }
            return { ...r };
          });
          setProducts(prd);
          return prd;
        })
        .catch(error => {
          toastr.error(`Ocorreu um erro inesperado ao listar os produtos: ${error.message}`);
        });
    }
  };

  const handleSetDerivationBySku = async (sku: string) => {
    return await productService
      .listProductsBySku(`${sku}`)
      .then(result => {
        const prd = result?.map(r => {
          if (!r.derivation || r.derivation === ' ') {
            r.derivation = 'SEM DERIVAÇÃO';
          }
          return { ...r };
        });
        setProducts(prd);

        // Se o resultado contiver apenas um produto, preenche automaticamente a derivação
        if (prd && prd.length === 1) {
          const product = prd[0];

          setDerivation(product.derivation ? product.derivation : 'SEM DERIVAÇÃO');

          // Se existe um item sendo editado, atualiza sua descrição e derivação
          if (orderItem) {
            handleTableChange({
              ...orderItem,
              product: {
                ...orderItem.product,
                derivation: product.derivation,
                description: product.description,
              },
            });

            // Se já tiver quantidade informada, salva o item automaticamente
            if (quantity && parseFloat(quantity) > 0) {
              handleSaveOrderItem(`${product.id}`);
            }
          }
        }

        return prd;
      })
      .catch(error => {
        toastr.error(`Ocorreu um erro inesperado ao listar os produtos: ${error.message}`);
      });
  };

  const handleListItems = useCallback(async () => {
    if (params.id) {
      setLoading(true);
      await listItemsByOrderId(
        params.id,
        `perPage=${perPage}&currentPage=${currentPage}&orderBy=${orderField}&orderDirection=${orderDirection}`,
      )
        .then(response => {
          setItems(response.data);
          if (response?.data?.length === 0) {
            setItems([{ ...initialItem, sequence: 1 }]);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
    setSku('');
    setDerivation('');
    setQuantity('');
    setOfferPercentage('');
    setAdditionPercentage('');
  }, [params.id]);

  const setEditableRow = async (sequence: number) => {
    setAutocompleteIsOpen(false);
    setItems(current => {
      return current.map(item => {
        if (item.sequence === sequence) {
          setOrderItem(item);
          item.isEditing = true;
          if (item?.product?.sku) {
            setSku(`${item?.product?.sku}`);
            setQuantity(`${item?.quantity}`);
            setDerivation(`${item?.product?.derivation}`);
            setOfferPercentage(`${item.offerPercentage || '0'}`);
            setAdditionPercentage(`${item.additionPercentage || '0'}`);
          }
        } else {
          if (item.isEditing && orderItem) {
            item = { ...orderItem };
          }

          item.isEditing = false;
        }
        return item;
      });
    });
  };

  const handleSavingData = async () => {
    if (
      !!sku &&
      !!derivation &&
      !!quantity &&
      quantity !== '0' &&
      (!!offerPercentage || userCommandedToSave) &&
      (!!additionPercentage || userCommandedToSave) &&
      (orderItem?.product?.sku !== sku ||
        orderItem?.quantity !== parseFloat(quantity) ||
        orderItem?.offerPercentage !== parseFloat(offerPercentage) ||
        orderItem?.additionPercentage !== parseFloat(additionPercentage))
    ) {
      await handleListProducts();
      const newProduct = products.find(p => {
        return (p.sku === sku && p.derivation === derivation) || p.derivation === 'SEM DERIVAÇÃO';
      });

      if (newProduct) {
        handleSaveOrderItem(`${newProduct.id}`);
      }
    }
  };

  const handleCreateOrder = async (item: IOrderItem) => {
    setLoading(true);
    await orderItemService
      .create(item)
      .then(() => {
        toastr.success('Item adicionado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateOrder = async (item: IOrderItem) => {
    setLoading(true);
    await orderItemService
      .update(`${item.id}`, item)
      .then(() => {
        toastr.success('Item atualizado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveOrderItem = async (productId: string) => {
    const item: IOrderItem = {
      id: orderItem?.id,
      orderId,
      sequence: orderItem?.sequence || 0,
      productId: productId,
      quantity: Number(quantity),
      price: orderItem?.price || 0,
      freightValue: orderItem?.freightValue || 0,
      discountFTZ: orderItem?.discountFTZ || 0,
      IPI_Percent: orderItem?.IPI_Percent || 0,
      IPI_Value: orderItem?.IPI_Value || 0,
      ICMSST_Value: orderItem?.ICMSST_Value || 0,
      ICMS_Percent: orderItem?.ICMS_Percent || 0,
      amount: orderItem?.amount || 0,
      offerPercentage: Number(offerPercentage) || 0,
      additionPercentage: Number(additionPercentage) || 0,
      offerValue: orderItem?.offerValue || 0,
      status: orderItem?.status || undefined,
      errorMessage: orderItem?.errorMessage || '',
    };

    if (orderItem?.id) {
      await handleUpdateOrder(item);
    } else {
      await handleCreateOrder(item);
    }
    handleListItems();
  };

  const handleTableChange = (item: IOrderItem) => {
    setItems(current => {
      return current.map(i => {
        if (i.id === item.id) {
          i = { ...item };
          i.isEditing = true;
        }
        return i;
      });
    });
  };

  const handleElementClick = (evt: any, name: string) => {
    if (isEditionDisabled()) {
      return;
    }

    const sequence = parseInt(`${evt?.target?.id?.split(`${name}-`)[1]}`);
    evt?.stopPropagation();

    setEditableRow(sequence);

    setTimeout(() => {
      const element = document.getElementById(`edt-${evt?.target?.id}`);
      element?.focus();
    }, 100);
  };

  const handleElementKeyDown = async (evt: any, name: string, sequence: number) => {
    if (isEditionDisabled()) {
      return;
    }
    setUserCommandToSave(false);
    const greaterSequence = items.find(i => i.sequence > sequence)?.sequence;
    const smallerSequence = items
      .filter(i => i.sequence < sequence)
      .sort((a, b) => b.sequence - a.sequence)
      .find(() => true)?.sequence;

    if (autocompleteIsOpen) {
      return;
    }
    if (evt.ctrlKey && evt.key === 'Delete') {
      const id = items.find(i => i.sequence === sequence)?.id;
      if (id) {
        handleRemoveIconClicked(id);
      }
    }
    if (evt.altKey && evt.key === 'ArrowDown') {
      return;
    }
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      setUserCommandToSave(true);
    }
    if (evt.code === 'ArrowDown' && !evt.altKey) {
      const thereIsNewRow = items.filter(i => !i.id);

      if (thereIsNewRow?.length !== 0) {
        return;
      }

      if (!greaterSequence) {
        setNewItemRow();
        return;
      }
      setEditableRow(greaterSequence);
      setTimeout(() => {
        const element = document.getElementById(`edt-${name}-${greaterSequence}`);
        element?.focus();
      }, 100);
    }

    if (evt.code === 'Escape') {
      clearNotSavedItem();
      setItems(current => {
        return current.map(i => {
          return { ...i, isEditing: false };
        });
      });

      if (!items.find(i => i.sequence === sequence)?.id && smallerSequence) {
        setEditableRow(smallerSequence);
        setTimeout(() => {
          const element = document.getElementById(`edt-${name}-${smallerSequence}`);
          element?.focus();
        }, 100);
      }
      return;
    }
    if (evt.code === 'ArrowUp') {
      clearNotSavedItem();
      if (!smallerSequence) {
        return;
      }
      setEditableRow(smallerSequence);
      setTimeout(() => {
        const element = document.getElementById(`edt-${name}-${smallerSequence}`);
        element?.focus();
      }, 200);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (isEditionDisabled()) {
      return;
    }
    if (event.code === 'Insert') {
      const itemsTmp = [...items];

      const thereIsNewRow = itemsTmp.filter(i => !i.id);

      if (thereIsNewRow?.length === 0) {
        setNewItemRow();
      }
    }
  };

  const handleChangeSku = (event: any, newValue: string, item: IOrderItem) => {
    event.stopPropagation();
    setDerivation('');
    setQuantity('');
    setOfferPercentage('');
    setAdditionPercentage('');
    handleTableChange({
      ...item,
      product: {
        ...item.product,
        sku: String(newValue),
        description: ' ',
        stockQuantity: 0,
      },
      price: 0,
      freightValue: 0,
      discountFTZ: 0,
      IPI_Percent: 0,
      IPI_Value: 0,
      ICMSST_Value: 0,
      ICMS_Percent: 0,
      amount: 0,
      offerValue: 0,
      status: undefined,
      errorMessage: null,
    });
    setSku(String(newValue));

    handleSetDerivationBySku(String(newValue));
  };

  const handleChangeDerivation = (event: any, newValue: string, item: IOrderItem) => {
    event.stopPropagation();
    const newDerivation = newValue === 'SEM DERIVAÇÃO' ? ' ' : String(newValue);
    setDerivation(newDerivation);
    const newProduct = products.find(p => {
      return (p.sku === sku && p.derivation === newDerivation) || p.derivation === 'SEM DERIVAÇÃO';
    });

    handleTableChange({
      ...item,
      product: {
        ...item.product,
        derivation: String(newValue),
        description: newProduct?.description,
      },
    });

    // Adicione esta condição para salvar automaticamente quando tiver produto e quantidade
    if (sku && quantity && parseFloat(quantity) > 0) {
      // Se encontrou o produto, salve imediatamente
      if (newProduct) {
        handleSaveOrderItem(`${newProduct.id}`);
      }
    }
  };

  useEffect(() => {
    if (items.length === 0) {
      setItems([{ ...initialItem, sequence: 1 }]);
    }
  }, [items?.length]);

  useEffect(() => {
    handleListItems();
    handleListSkus();

    socketIoService.connect(user);
    socketIoService.subscribeToCalculateItem(updatedItem => {
      handleLoadOrder();
      props.itemsUpdated?.();
      setItems(state => {
        return state.map(item => {
          return item.id === updatedItem.id ? updatedItem : item;
        });
      });
    });
    return () => {
      socketIoService.disconnect();
    };
  }, []);

  useEffect(() => {
    handleLoadOrder();
  }, [orderId]);

  useEffect(() => {
    handleSavingData();
  }, [sku, quantity, derivation, offerPercentage, additionPercentage, userCommandedToSave]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isEditingItem = items.some(item => item.isEditing);
      const isClickingOnFormFields = (event.target as HTMLElement)?.closest(
        '.MuiAutocomplete-root, .MuiTextField-root',
      );

      if (!isEditingItem || !isClickingOnFormFields) {
        clearNotSavedItem();
      }

      handleElementClick(event, 'out');
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [items]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [items]);

  return (
    <OrderItemListContainer>
      <Dialog
        open={isRemoveDialogOpen}
        onClose={() => {
          setIsRemoveDialogOpen(!isRemoveDialogOpen);
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '-10px' }}>
            <strong style={{ fontSize: '16px' }}>Confirma a exclusão do ítem?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginRight: '10px' }}>
          <Button color="error" variant="contained" onClick={handleDenyToRemove} disabled={loading}>
            Cancelar
          </Button>
          <Button onClick={handleRemoveItem} autoFocus loading={loading} disabled={loading} variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {orderId && (
        <OrdemItemEdit
          openDialog={openDialog}
          orderId={orderId}
          id={itemId || undefined}
          handleClose={handleCloseDialog}
          itemsUpdated={props.itemsUpdated}
        />
      )}
      {loading && <BackdropCustom />}
      <IconButtonAdd onClick={() => handleToEdit(null)} disabled={isEditionDisabled()}>
        <FiPlusCircle size={50} />
      </IconButtonAdd>
      {isSteper && (
        <ButtonSaveContainer>
          <Button variant="contained" onClick={() => handleToEdit(null)}>
            Adicionar Itens
          </Button>
        </ButtonSaveContainer>
      )}

      <TableItems>
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': {
                borderBottom: 1,
                borderColor: '#E5E5E5',
              },
            }}
          >
            <TableCellCustom size="small" style={{ minWidth: '80px', textAlign: 'center' }}>
              Ações
            </TableCellCustom>

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="sequence"
              label="Seq."
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="sku"
              label="Produto"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="derivation"
              label="Derivação"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="description"
              label="Descrição"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="quantity"
              label="Quantidade"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="offerPercentage"
              label="% Oferta"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="additionPercentage"
              label="% Acréscimo"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="price"
              label="Preço Unitário"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="price"
              label="Uni. c/ Imp."
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="IPI_Value"
              label="IPI"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="ICMSST_Value"
              label="ICMS ST"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="status"
              label="Situação"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="amount"
              label="Valor Total"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="stockQuantity"
              label="Estoque"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map(item => {
              return (
                <TableRow
                  key={item.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCellCustom size="small" style={{ textAlign: 'center' }} width={60}>
                    <Tooltip title="Deletar" placement="top">
                      <span>
                        <IconButton
                          disabled={isEditionDisabled() || !item.id}
                          onClick={() => handleRemoveIconClicked(`${item.id}`)}
                        >
                          <FiTrash size={15} color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#F90303'} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title="Editar" placement="top">
                      <span>
                        <IconButton
                          disabled={isEditionDisabled() || !item.id}
                          onClick={() => handleToEdit(`${item.id}`)}
                        >
                          <AiTwotoneEdit
                            size={15}
                            color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#0052BE'}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCellCustom>

                  <TableCellCustom size="small">{item.sequence}</TableCellCustom>
                  <TableCellCustom
                    size="small"
                    sx={{ whiteSpace: 'nowrap' }}
                    id={`sku-${item.sequence}`}
                    onClick={evt => {
                      handleElementClick(evt, 'sku');
                    }}
                    onKeyDown={evt => handleElementKeyDown(evt, 'sku', item.sequence)}
                  >
                    {item.isEditing ? (
                      <Autocomplete
                        id={`edt-sku-${item.sequence}`}
                        options={skus}
                        onClick={evt => evt.stopPropagation()}
                        disableClearable={true}
                        value={sku}
                        onOpen={() => setAutocompleteIsOpen(true)}
                        onClose={() => setAutocompleteIsOpen(false)}
                        onChange={(e: any, newValue) => {
                          handleChangeSku(e, newValue, item);
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return option === value || true;
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            sx={{ minWidth: '150px' }}
                            size="small"
                            label="Produto"
                            onClick={evt => evt.stopPropagation()}
                            InputProps={{
                              ...params?.InputProps,
                              endAdornment: (
                                <>
                                  {loadingSkus ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params?.InputProps?.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    ) : (
                      `${item?.product?.sku} `
                    )}
                  </TableCellCustom>
                  <TableCellCustom
                    size="small"
                    sx={{ whiteSpace: 'nowrap' }}
                    id={`derivation-${item.sequence}`}
                    onClick={evt => handleElementClick(evt, 'derivation')}
                    onKeyDown={evt => handleElementKeyDown(evt, 'derivation', item.sequence)}
                  >
                    {item.isEditing ? (
                      <Autocomplete
                        id={`edt-derivation-${item.sequence}`}
                        options={products.map(p => p.derivation)}
                        onClick={evt => evt.stopPropagation()}
                        onClose={() => setAutocompleteIsOpen(false)}
                        onOpen={() => {
                          setAutocompleteIsOpen(true);
                        }}
                        onKeyDown={evt => {
                          if (evt.altKey && evt.key === 'ArrowDown') {
                            setAutocompleteIsOpen(true);
                          }
                        }}
                        disableClearable={true}
                        value={derivation === ' ' ? 'SEM DERIVAÇÃO' : derivation}
                        onChange={(e: any, newValue) => {
                          handleChangeDerivation(e, newValue, item);
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return option === value || true;
                        }}
                        onFocus={() => {
                          handleListProducts();
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            sx={{ minWidth: '110px' }}
                            size="small"
                            label="Derivação"
                            onClick={evt => evt.stopPropagation()}
                          />
                        )}
                      />
                    ) : (
                      `${item.product?.derivation}`
                    )}
                  </TableCellCustom>
                  <TableCellCustom size="small">{item.product?.description}</TableCellCustom>

                  <TableCellCustom
                    size="small"
                    width={70}
                    onClick={(evt: any) => handleElementClick(evt, 'quantity')}
                    onKeyDown={evt => handleElementKeyDown(evt, 'quantity', item.sequence)}
                    id={`quantity-${item.sequence}`}
                  >
                    {item.isEditing ? (
                      <NumericFormat
                        id={`edt-quantity-${item.sequence}`}
                        size="small"
                        customInput={TextField}
                        label=""
                        value={quantity}
                        decimalScale={item.product?.measurementUnit?.precision || 0}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale
                        valueIsNumericString
                        onClick={(evt: any) => evt.stopPropagation()}
                        onValueChange={v => {
                          setQuantity(v.value || '');
                          handleTableChange({ ...item, quantity: parseFloat(v.value) || 0 });
                        }}
                      />
                    ) : (
                      formatCurrencyNumber(item.quantity, item?.product?.measurementUnit?.precision)
                    )}
                  </TableCellCustom>
                  <TableCellCustom
                    sx={{ minWidth: '70px' }}
                    size="small"
                    onClick={(evt: any) => handleElementClick(evt, 'offerPercentage')}
                    onKeyDown={evt => handleElementKeyDown(evt, 'offerPercentage', item.sequence)}
                    id={`offerPercentage-${item.sequence}`}
                  >
                    {item.isEditing ? (
                      <NumericFormat
                        id={`edt-offerPercentage-${item.sequence}`}
                        size="small"
                        customInput={TextField}
                        label=""
                        value={offerPercentage}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale
                        valueIsNumericString
                        onClick={(evt: any) => evt.stopPropagation()}
                        onValueChange={v => {
                          setOfferPercentage(v.value || '');
                          handleTableChange({ ...item, offerPercentage: parseFloat(v.value) || 0 });
                        }}
                      />
                    ) : (
                      formatCurrencyNumber(item.offerPercentage, 2)
                    )}
                  </TableCellCustom>
                  <TableCellCustom
                    size="small"
                    width={70}
                    onClick={(evt: any) => handleElementClick(evt, 'additionPercentage')}
                    onKeyDown={evt => handleElementKeyDown(evt, 'additionPercentage', item.sequence)}
                    id={`additionPercentage-${item.sequence}`}
                  >
                    {item.isEditing ? (
                      <NumericFormat
                        id={`edt-additionPercentage-${item.sequence}`}
                        size="small"
                        customInput={TextField}
                        label=""
                        value={additionPercentage}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale
                        valueIsNumericString
                        onClick={(evt: any) => evt.stopPropagation()}
                        onValueChange={v => {
                          setAdditionPercentage(v.value || '');
                          handleTableChange({ ...item, additionPercentage: parseFloat(v.value) || 0 });
                        }}
                      />
                    ) : (
                      formatCurrencyNumber(item.additionPercentage || 0, 2)
                    )}
                  </TableCellCustom>

                  <TableCellCustom size="small">
                    {`R$ ${(item.price ? item.price : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>

                  <TableCellCustom size="small">
                    {`R$ ${(item.amount && item.quantity !== 0 ? item.amount / item.quantity : 0).toLocaleString(
                      'pt-BR',
                      {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}`}
                  </TableCellCustom>

                  <TableCellCustom size="small" sx={{ whiteSpace: 'nowrap' }}>
                    {`R$ ${(item.IPI_Value ? item.IPI_Value : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>

                  <TableCellCustom size="small" sx={{ whiteSpace: 'nowrap' }}>
                    {`R$ ${(item.ICMSST_Value ? item.ICMSST_Value : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>

                  <TableCellCustom size="small" statusid={item.status?.id}>
                    <Tooltip title={item.errorMessage} placement="top">
                      <strong>{item.status?.description}</strong>
                    </Tooltip>
                  </TableCellCustom>

                  <TableCellCustom size="small" sx={{ whiteSpace: 'nowrap' }}>
                    {`R$ ${(item.amount ? item.amount : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>
                  <TableCellCustom size="small" width={60}>
                    {item.product?.stockQuantity && item.product?.stockQuantity > 0
                      ? formatCurrencyNumber(item.product?.stockQuantity, item?.product?.measurementUnit?.precision)
                      : '0,00'}
                  </TableCellCustom>
                </TableRow>
              );
            })}
        </TableBody>
      </TableItems>
      <TableBodyCard>
        {items &&
          items.map(item => {
            return (
              <TableCard key={item.id}>
                <TableCardRow>
                  <TableCardTitle>Seq.</TableCardTitle>
                  <TableCardDescription>{item.sequence}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Descrição</TableCardTitle>
                  <Tooltip title={item.product?.description} placement="top">
                    <TableCardDescription>{item.product?.description}</TableCardDescription>
                  </Tooltip>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Produto</TableCardTitle>
                  <TableCardDescription>
                    {`${item.product?.sku}  ${
                      item.product?.derivation && item.product?.derivation?.length >= 1
                        ? `- ${item.product?.derivation}`
                        : ''
                    } `}
                  </TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Qtd.</TableCardTitle>
                  <TableCardDescription>{item.quantity}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Pre. Uni.</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.price ? item.price : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Uni. c/ Imp.</TableCardTitle>
                  <TableCardDescription>
                    {`R$ ${(item.amount && item.quantity !== 0 ? item.amount / item.quantity : 0).toLocaleString(
                      'pt-BR',
                      {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}`}
                  </TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>IPI</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.IPI_Value ? item.IPI_Value : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>ICMS ST</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.ICMSST_Value ? item.ICMSST_Value : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>

                <TableCardRow>
                  <TableCardTitle>Situação</TableCardTitle>
                  <TableCardDescription statusid={item.status?.id}>
                    <Tooltip title={item.errorMessage} placement="top" enterTouchDelay={0} leaveTouchDelay={10}>
                      <strong>{item.status?.description}</strong>
                    </Tooltip>
                  </TableCardDescription>
                </TableCardRow>

                <TableCardRow>
                  <TableCardTitle>Valor Total</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.amount ? item.amount : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Ações</TableCardTitle>
                  <TableCardDescription>
                    <Tooltip title="Deletar" placement="top">
                      <span>
                        <IconButton
                          disabled={isEditionDisabled()}
                          onClick={() => handleRemoveIconClicked(`${item.id}`)}
                        >
                          <FiTrash size={15} color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#F90303'} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title="Editar" placement="top">
                      <span>
                        <IconButton disabled={isEditionDisabled()} onClick={() => handleToEdit(`${item.id}`)}>
                          <AiTwotoneEdit
                            size={15}
                            color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#0052BE'}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCardDescription>
                </TableCardRow>
              </TableCard>
            );
          })}
      </TableBodyCard>
      <hr />
      <TotalsContainer>
        <TotalsValues>
          <strong>Total Produtos:</strong>
          <div>{formatCurrency(order?.grossTotalValue || 0)}</div>
        </TotalsValues>

        <TotalsValues>
          <strong>IPI:</strong>
          <div>{formatCurrency(order?.IPI_TotalValue || 0)}</div>
        </TotalsValues>
        <TotalsValues>
          <strong>ICMS ST:</strong>
          <div>{formatCurrency(order?.ICMSST_TotalValue || 0)}</div>
        </TotalsValues>

        <TotalsValues>
          <strong>Desconto ZF:</strong>
          <div>{formatCurrency(order?.discountFTZTotalValue || 0)}</div>
        </TotalsValues>
        <TotalsValues>
          <strong>Total Pedido:</strong>
          <div>{formatCurrency(order?.totalValue || 0)}</div>
        </TotalsValues>
      </TotalsContainer>
    </OrderItemListContainer>
  );
});

export default OrderItemList;
