import AppError from '../errors/AppError';
import { useAuth } from '../hooks/auth';
import { IOrderItem } from '../interfaces/IOrderItem';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useOrderItemService = () => {
  const requestOrderItems = useRequest<IOrderItem>();
  const pathOrderItems = 'order-items';
  const { state } = useAuth();

  const listItemsByOrderId = async (orderId: string, queryParams: string): Promise<IPaginationReturn<IOrderItem[]>> => {
    const response = await requestOrderItems
      .getManyPaginated({
        path: `${pathOrderItems}/order/${orderId}?${queryParams}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const create = async (orderItem: IOrderItem): Promise<IOrderItem> => {
    return await requestOrderItems
      .post({
        path: `${pathOrderItems}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
        body: orderItem,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const update = async (itemId: string, orderItem: IOrderItem): Promise<IOrderItem> => {
    return await requestOrderItems
      .put({
        path: `${pathOrderItems}/${itemId}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
        body: orderItem,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listById = async (itemId: string): Promise<IOrderItem> => {
    return await requestOrderItems
      .getOne({
        path: `${pathOrderItems}/${itemId}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const remove = async (itemId: string): Promise<void> => {
    await requestOrderItems
      .remove({
        path: `${pathOrderItems}/${itemId}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then()
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const removeAll = async (orderId: string): Promise<void> => {
    await requestOrderItems
      .remove({
        path: `${pathOrderItems}/all/${orderId}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then()
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { listItemsByOrderId, create, update, listById, remove, removeAll };
};

export { useOrderItemService };
