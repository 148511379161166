import AppError from '../errors/AppError';
import IOrderType from '../interfaces/IOrderType';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useOrderTypesService = () => {
  const request = useRequest<IOrderType>();
  const path = 'order-types';

  const findAll = async (queryParams: string): Promise<IPaginationReturn<IOrderType[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const create = async (orderTypes: IOrderType): Promise<IOrderType> => {
    return await request
      .post({ path: `${path}`, sendAuthorization: true, body: orderTypes })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const update = async (id: number, orderTypes: IOrderType): Promise<IOrderType> => {
    return await request
      .put({ path: `${path}/${id}`, sendAuthorization: true, body: orderTypes })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const remove = async (id: number): Promise<void> => {
    return await request
      .remove({ path: `${path}/${id}`, sendAuthorization: true })
      .then(() => {
        return;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const find = async (id: number): Promise<IOrderType> => {
    return await request
      .getOne({ path: `${path}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const uploadIcon = async (id: number, data: FormData): Promise<IOrderType> => {
    const response = await request
      .patch({
        path: `${path}/icon/${id}`,
        sendAuthorization: true,
        body: data,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  return { findAll, update, find, create, remove, uploadIcon };
};

export { useOrderTypesService };
