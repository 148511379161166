import AppError from '../errors/AppError';
import { IResponseValidateHubSpotDealId } from '../interfaces/IValidateHubSpotDealId';

import { useRequest } from './useRequest';

const useHubSpotService = () => {
  const request = useRequest<IResponseValidateHubSpotDealId>();
  const path = 'orders/hubspot';

  const validateHubSpotDealId = async (
    dealId: string,
    orderTypeId: number,
    selectedSellerId: string,
  ): Promise<IResponseValidateHubSpotDealId> => {
    return await request
      .getOne({
        path: `${path}/validate/deal/${dealId}?orderTypeId=${orderTypeId}&selectedSellerId=${selectedSellerId}`,
        sendAuthorization: true,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const sendPDFToHubSpot = async (oderId: string): Promise<IResponseValidateHubSpotDealId> => {
    return await request
      .put({ path: `${path}/send-file/${oderId}?offset=${new Date().getTimezoneOffset()}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const updateAmountInHubSpot = async (oderId: string): Promise<void> => {
    await request
      .put({ path: `${path}/amount/${oderId}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return;
  };

  const updateDealStageInHubSpot = async (oderId: string, event: 'CLOSE' | 'SAVE'): Promise<void> => {
    await request
      .put({
        path: `${path}/stage/${oderId}`,
        body: {
          event,
        },
        sendAuthorization: true,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return;
  };

  return { validateHubSpotDealId, sendPDFToHubSpot, updateAmountInHubSpot, updateDealStageInHubSpot };
};

export { useHubSpotService };
