import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IPage from '../../interfaces/IPage';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import { PageCard } from '../../components/page-card/PageCard';
import { Button } from '../../components/button/Button';
import OrderCustomerForm from './OrderCustomerForm';
import OrderDataForm from './OrderDataForm';
import OrderItemList from './OrderItemList';
import IOrderCustomerFormProps from './interfaces/IOrderCustomerFormProps';
import IOrderDataFormProps from './interfaces/IOrderDataFormProps';
import { useParams } from 'react-router-dom';
import IOrderItemListProps from './interfaces/IOrderItemListProps';
import { useHubSpotService } from '../../services/useHubSpotService';
import { useToastr } from '../../hooks/useToastr';
import { useAuth } from '../../hooks/auth';

const steps = ['Selecionar Cliente', 'Informações Gerais', 'Itens'];

const OrderSteper: React.FC<IPage> = ({ title }) => {
  window.document.title = title;
  const { orderParams } = useAuth();

  const hubSpotService = useHubSpotService();

  const toastr = useToastr();

  const [activeStep, setActiveStep] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const params = useParams();

  React.useEffect(() => {
    if (params.id === '0') {
      setActiveStep(0);
    }
  }, [params.id]);

  const orderCustomerForm = useRef<IOrderCustomerFormProps>(null);
  const orderDataForm = useRef<IOrderDataFormProps>(null);
  const orderItemList = useRef<IOrderItemListProps>(null);

  const handleDataToHubSpot = (event: 'CLOSE' | 'SAVE') => {
    if (orderParams?.integrateHubSpot && params.id) {
      hubSpotService.sendPDFToHubSpot(params.id).catch(error => {
        toastr.error(error.message || 'Erro ao enviar documento para o HUBSpot. Contate o suporte.');
      });

      hubSpotService.updateAmountInHubSpot(params.id).catch(error => {
        toastr.error(error.message || 'Erro ao atualizar valor do pedido no HUBSpot. Contate o suporte.');
      });

      hubSpotService.updateDealStageInHubSpot(params.id, event).catch(error => {
        toastr.error(error.message || 'Erro ao atualizar estágio da negociação no HUBSpot. Contate o suporte.');
      });
    }
  };

  const handleFinish = () => {
    orderItemList.current?.finish();
    handleDataToHubSpot('SAVE');
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      const success = await orderCustomerForm.current?.save();
      if (!success) {
        return;
      }
    }
    if (activeStep === 1) {
      const success = await orderDataForm.current?.save();
      if (!success) {
        return;
      }
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };

  window.addEventListener('resize', resizeScreen);

  const handleClearForm = () => {
    orderCustomerForm.current?.clearForm();
  };

  return (
    <>
      <TitleContainer>
        <h1>Pedido - {steps[activeStep]}</h1>
      </TitleContainer>
      <PageCard>
        <Stepper activeStep={activeStep} orientation={screenWidth > 500 ? 'horizontal' : 'vertical'}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </PageCard>
      <PageCard>
        <div style={{ minHeight: '30vh', overflow: 'hidden' }}>
          {activeStep === 0 && <OrderCustomerForm ref={orderCustomerForm} key={params.id} />}
          {activeStep === 1 && <OrderDataForm ref={orderDataForm} key={params.id} />}
          {activeStep === 2 && <OrderItemList ref={orderItemList} key={params.id} />}
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button buttonSize="medium" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Voltar
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep === 0 && params.id === '0' && (
            <Button variant="contained" buttonSize="medium" color="error" onClick={handleClearForm}>
              Limpar
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button variant="contained" buttonSize="medium" onClick={handleNext}>
              Próximo
            </Button>
          )}
          {activeStep === 2 && (
            <Button variant="contained" buttonSize="medium" onClick={handleFinish}>
              Próximo
            </Button>
          )}
        </Box>
      </PageCard>
    </>
  );
};

export default OrderSteper;
