import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '../../../components/button/Button';

interface IProps {
  open: boolean;
  confirm: () => void;
  deny: () => void;
}

const OrderConfirmDialog = ({ open, confirm, deny }: IProps) => {
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong style={{ fontSize: '16px' }}>
              O pedido foi duplicado com sucesso. Deseja acessar o novo pedido?
            </strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deny} color="error" variant="contained">
            Agora não
          </Button>
          <Button onClick={confirm} autoFocus variant="contained">
            Acessar Pedido
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderConfirmDialog;
