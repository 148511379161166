import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import validateUuId from '../../util/validateUuid';
import { useOrderService } from '../../services/useOrderService';
import { IOrder } from '../../interfaces/IOrder';
import styled from 'styled-components';
import { Autocomplete, CircularProgress, Dialog, DialogContent, Grid, TextField } from '@mui/material';
import { useOrderTypesService } from '../../services/useOrderTypesService';
import IOrderType from '../../interfaces/IOrderType';

import { ISeller } from '../../interfaces/ISeller';
import AsyncSearch, { IOption } from '../../components/async-search/AsyncSearch';
import { useSellersService } from '../../services/useSellersService';
import IFormError from '../../interfaces/IFormError';
import { useToastr } from '../../hooks/useToastr';
import { useCarriersService } from '../../services/useCarriersService';
import { ICarrier } from '../../interfaces/ICarrier';
import { IPaymentCondition } from '../../interfaces/IPaymentCondition';
import { usePaymentConditionService } from '../../services/usePaymentConditionService';
import { IPaymentForm } from '../../interfaces/IPaymentForm';
import { usePaymentFormService } from '../../services/usePaymentFormService';
import IOrderDataFormProps from './interfaces/IOrderDataFormProps';
import * as yup from 'yup';
import { useValidateSchema } from '../../services/useValidateSchema';
import { DatePickerMui, LocalizationProviderMui } from './OrderDataForm.style';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { useAuth } from '../../hooks/auth';
import { ICustomer } from '../../interfaces/ICustomer';

const Container = styled.div`
  padding: 20px 0;
  position: relative;
`;

const OrderDataForm = forwardRef<IOrderDataFormProps, { handleItemsUpdated?: () => Promise<void> }>((props, ref) => {
  useImperativeHandle(ref, () => ({
    listOrder,
    async save() {
      return await updateOrder();
    },
    handleItemsUpdated: props.handleItemsUpdated,
  }));

  const { orderParams } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<IOrder | null>(null);

  const [orderType, setOrderType] = useState<IOrderType>({ description: '' });
  const [orderTypes, setOrderTypes] = useState<IOrderType[]>([]);

  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [previousCustomer, setPreviousCustomer] = useState<ICustomer | null>(null);

  const [loadingSellers, setLoadingSellers] = useState<boolean>(false);
  const [loadingCarriers, setLoadingCarriers] = useState<boolean>(false);
  const [loadingRedeliveryCarriers, setLoadingRedeliveryCarriers] = useState<boolean>(false);
  const [loadingPaymentConditions, setLoadingPaymentConditions] = useState<boolean>(false);
  const [loadingPaymentForm, setLoadingPaymentForm] = useState<boolean>(false);

  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [sellerOptions, setSellerOptions] = useState<IOption[]>([]);
  const [sellerOption, setSellerOption] = useState<IOption | null>(null);

  const [paymentConditions, setPaymentConditions] = useState<IPaymentCondition[]>([]);
  const [paymentConditionOptions, setPaymentConditionOptions] = useState<IOption[]>([]);
  const [paymentConditionOption, setPaymentConditionOption] = useState<IOption | null>(null);

  const [paymentForms, setPaymentForms] = useState<IPaymentForm[]>([]);
  const [paymentFormOptions, setPaymentFormOptions] = useState<IOption[]>([]);
  const [paymentFormOption, setPaymentFormOption] = useState<IOption | null>(null);

  const [carriers, setCarriers] = useState<ICarrier[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<IOption[]>([]);
  const [carrierOption, setCarrierOption] = useState<IOption | null>(null);

  const [redeliveryCarriers, setRedeliveryCarriers] = useState<ICarrier[]>([]);
  const [redeliveryCarrierOptions, setRedeliveryCarrierOptions] = useState<IOption[]>([]);
  const [redeliveryCarrierOption, setRedeliveryCarrierOption] = useState<IOption | null>(null);

  const [orderDate, setOrderDate] = useState<Dayjs | null>(dayjs());
  const [orderComments, setOrderComments] = useState<string>('');
  const [orderInternalComments, setOrderInternalComments] = useState<string>('');
  const [customerOrderNumber, setCustomerOrderNumber] = useState<string>('');
  const [forecastDate, setForecastDate] = useState<Dayjs | null>(null);
  const [calculatingOrder, setCalculatingOrder] = useState(false);

  const [baseDate, setBaseDate] = useState<Dayjs | null>(null);

  const [errors, setErrors] = useState<IFormError | null>();

  const params = useParams();
  const timeout = useRef<any>(0);

  const orderTypeService = useOrderTypesService();
  const orderService = useOrderService();
  const sellerService = useSellersService();
  const carrierService = useCarriersService();
  const paymentConditionService = usePaymentConditionService();
  const paymentFormService = usePaymentFormService();

  const validateSchema = useValidateSchema();
  const toastr = useToastr();

  const sellerToOption = (seller: ISeller): IOption => {
    return {
      value: `${seller.id}`,
      description: `${seller.code} ${seller.name}`,
    };
  };

  const carrierToOption = (carrier: ICarrier): IOption => {
    return {
      value: `${carrier.id}`,
      description: `${carrier.code} ${carrier.name}`,
    };
  };

  const paymentConditionToOption = (paymentCondition: IPaymentCondition): IOption => {
    return {
      value: `${paymentCondition.id}`,
      description: `${paymentCondition.code} ${paymentCondition.name}`,
    };
  };

  const paymentFormToOption = (paymentForm: IPaymentForm): IOption => {
    return {
      value: `${paymentForm.id}`,
      description: `${paymentForm.code} ${paymentForm.name}`,
    };
  };

  const handleSetSeller = (option: IOption | null) => {
    if (option) {
      setSellerOption(option);
      const selected = sellers.find(paymentCondition => {
        return paymentCondition.id === option.value;
      });
      if (selected?.id) {
        setSellerOption(sellerToOption(selected));
      } else {
        setSellerOption(null);
      }
    }
  };

  const handleSetPaymentCondition = (option: IOption | null) => {
    if (option) {
      setPaymentConditionOption(option);
      const selected = paymentConditions.find(paymentCondition => {
        return paymentCondition.id === option.value;
      });
      if (selected?.id) {
        setPaymentConditionOption(paymentConditionToOption(selected));
      } else {
        setPaymentConditionOption(null);
      }
    }
  };

  const handleSetPaymentForm = (option: IOption | null) => {
    if (option) {
      setPaymentFormOption(option);
      const selected = paymentForms.find(paymentForm => {
        return paymentForm.id === option.value;
      });
      if (selected?.id) {
        setPaymentFormOption(paymentFormToOption(selected));
      } else {
        setPaymentFormOption(null);
      }
    }
  };

  const handleSetCarrier = (option: IOption | null) => {
    if (option) {
      setCarrierOption(option);
      const selected = carriers.find(carrier => {
        return carrier.id === option.value;
      });
      if (selected?.id) {
        setCarrierOption(carrierToOption(selected));
      } else {
        setCarrierOption(null);
      }
    }
  };

  const handleSetRedeliveryCarrier = (option: IOption | null) => {
    if (option) {
      setRedeliveryCarrierOption(option);
      const selected = redeliveryCarriers.find(carrier => {
        return carrier.id === option.value;
      });
      if (selected?.id) {
        setRedeliveryCarrierOption(carrierToOption(selected));
      } else {
        setRedeliveryCarrierOption(null);
      }
    }
  };

  const updateOrder = useCallback(async () => {
    setLoading(true);
    if (!order) {
      return false;
    }

    const newOrder: IOrder = {
      id: order.id,
      branchOfficeId: order.branchOffice?.id || '',
      orderTypeId: orderType.id || 1,
      customerId: customer?.id || order.customerId,
      sellerId: sellerOption?.value || '',
      orderNumber: order.orderNumber,
      carrierId: carrierOption?.value,
      redeliveryCarrierId: redeliveryCarrierOption?.value,
      paymentConditionId: paymentConditionOption?.value,
      paymentFormId: paymentFormOption?.value,
      comments: orderComments,
      internalComments: orderInternalComments,
      customerOrderNumber,
      forecastDate: forecastDate?.format('YYYY-MM-DD'),
      baseDate: baseDate?.format('YYYY-MM-DD'),
    };

    const schema = yup.object({
      orderTypeId: yup.number().required('Tipo de pedido obrigatório'),
      sellerId: yup.string().uuid('Representante obrigatório').required('Representante obrigatório'),
      carrierId: orderParams?.carrierRequired
        ? yup.string().uuid('Transportadora obrigatória').required('Transportadora obrigatória')
        : yup.string().nullable(),
      paymentConditionId: yup
        .string()
        .uuid('Condição de paragamento obrigatória')
        .required('Condição de paragamento obrigatória'),
      paymentFormId: orderParams?.paymentFormRequired
        ? yup.string().uuid('Forma de pagamento obrigatória').required('Forma de pagamento obrigatória')
        : yup.string().nullable(),
    });

    const result = await validateSchema.validate(schema, newOrder);
    if (!result.isValid) {
      setLoading(false);
      setErrors(result.errors);
      return result.isValid;
    }

    return await orderService
      .updateOrder(`${order.id}`, newOrder)
      .then(async () => {
        if (previousCustomer?.id !== customer?.id) {
          // recalcualr pedido quando houver alteração do cliente //
          setCalculatingOrder(true);
          await orderService
            .calculateOrder(`${order?.id}`)
            .then(async () => {
              if (props.handleItemsUpdated) {
                await props.handleItemsUpdated();
              }
            })
            .catch(error => {
              toastr.error(`Ocorreu um erro ao recalcular o pedido: ${error.message}`);
            });
        }

        toastr.success('Pedido alterado com sucesso.');
        return true;
      })
      .catch(error => {
        toastr.error(error.message);

        return false;
      })
      .finally(() => {
        setLoading(false);
        setCalculatingOrder(false);
      });
  }, [
    previousCustomer,
    customer,
    orderType,
    sellerOption,
    carrierOption,
    redeliveryCarrierOption,
    paymentConditionOption,
    paymentFormOption,
    orderComments,
    orderInternalComments,
    customerOrderNumber,
    order,
    forecastDate,
    baseDate,
  ]);

  const listOrder = async (id: string) => {
    setLoading(true);
    await orderService
      .listOrder(id, true)
      .then(result => {
        if (result) {
          setOrder({
            ...result,
          });

          if (result?.customer?.defaultPaymentConditionId && !result.paymentCondition) {
            const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc`;

            listPaymentConditionService(url, result?.customer?.defaultPaymentConditionId);
          }

          if (result?.customer?.defaultPaymentFormId && !result.paymentForm) {
            const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc`;

            listPaymentFormsService(url, result?.customer?.defaultPaymentFormId);
          }

          if (result?.customer?.defaultCarrierId && !result?.carrier) {
            const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc`;

            listCarriersService(url, result?.customer?.defaultCarrierId);
          }

          if (result?.customer?.redeliveryCarrierId && !result?.redeliveryCarrier) {
            const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc`;

            listRedeliveryCarriersService(url, result?.customer?.redeliveryCarrierId);
          }
        }

        result?.orderType && setOrderType(result.orderType);
        result?.orderDate && setOrderDate(dayjs(result.orderDate));
        result?.seller && setSellerOption(sellerToOption(result.seller));
        result?.paymentCondition && setPaymentConditionOption(paymentConditionToOption(result.paymentCondition));
        result?.paymentForm && setPaymentFormOption(paymentFormToOption(result.paymentForm));
        result?.carrier && setCarrierOption(carrierToOption(result.carrier));
        result?.redeliveryCarrier && setRedeliveryCarrierOption(carrierToOption(result.redeliveryCarrier));
        result?.comments && setOrderComments(result.comments);
        result?.internalComments && setOrderInternalComments(result.internalComments);
        result?.customerOrderNumber && setCustomerOrderNumber(result.customerOrderNumber);
        result?.forecastDate && setForecastDate(dayjs(result.forecastDate));
        result?.baseDate && setBaseDate(dayjs(result.baseDate));

        setCustomer(result?.customer || null);
        setPreviousCustomer(result?.customer || null);

        if (result?.customer?.customerGroup?.customers) {
          setCustomers(result.customer.customerGroup?.customers);
        } else {
          let array: ICustomer[] = [];

          if (result?.customer) {
            array.push(result?.customer);
            setCustomers(array);
          }
        }
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listOrderTypes = async () => {
    const url = `perPage=1000&currentPage=1&orderBy=description&orderDirection=ASC`;

    setLoading(true);
    await orderTypeService
      .findAll(url)
      .then(result => {
        if (result) {
          setOrderTypes(result.data.filter(value => value.isActive === true));
        } else {
          setOrderTypes([]);
        }
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listCarriersService = async (url: string, defaultCarrierId: string = '') => {
    carrierService
      .listDynamically(url)
      .then(result => {
        if (result) {
          setCarriers(result.data);
          const options: IOption[] = result.data.map(carrierToOption);

          const isCarrierOnArray = options.find(opt => opt.value === carrierOption?.value);
          if (carrierOption?.value && !isCarrierOnArray) {
            options.push(carrierOption);
          }
          setCarrierOptions(options);

          const defaultCarrierExists = result.data.find(value => value.id === defaultCarrierId);

          if (defaultCarrierExists) {
            setCarrierOption(carrierToOption(defaultCarrierExists));
          }
        } else {
          setCarrierOptions([]);
        }
      })
      .catch(error => {
        setCarrierOptions([]);
        toastr.error(error.message);
      })
      .finally(() => {
        setLoadingCarriers(false);
      });
  };

  const listRedeliveryCarriersService = async (url: string, redeliveryCarrierId: string = '') => {
    carrierService
      .listDynamically(url)
      .then(result => {
        if (result) {
          setRedeliveryCarriers(result.data);
          const options: IOption[] = result.data.map(carrierToOption);

          const isRedeliveryCarrierOnArray = options.find(opt => opt.value === redeliveryCarrierOption?.value);
          if (redeliveryCarrierOption?.value && !isRedeliveryCarrierOnArray) {
            options.push(redeliveryCarrierOption);
          }
          setRedeliveryCarrierOptions(options);

          const defaultRedeliveryCarrierExists = result.data.find(value => value.id === redeliveryCarrierId);

          if (defaultRedeliveryCarrierExists) {
            setRedeliveryCarrierOption(carrierToOption(defaultRedeliveryCarrierExists));
          }
        } else {
          setRedeliveryCarrierOptions([]);
        }
      })
      .catch(error => {
        setRedeliveryCarrierOptions([]);
        toastr.error(error.message);
      })
      .finally(() => {
        setLoadingRedeliveryCarriers(false);
      });
  };

  const listCarriers = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoadingCarriers(true);
      const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc&filter=${filter}`;

      await listCarriersService(url);
    }, timeout.current);
  };

  const listRedeliveryCarriers = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoadingRedeliveryCarriers(true);
      const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc&filter=${filter}`;

      await listRedeliveryCarriersService(url);
    }, timeout.current);
  };

  const listPaymentConditionService = async (url: string, defaultPaymentConditionId: string = '') => {
    paymentConditionService
      .listDynamically(url)
      .then(result => {
        if (result) {
          setPaymentConditions(result.data);
          const options: IOption[] = result.data.map(paymentConditionToOption);

          const isPaymentConditionOnArray = options.find(opt => opt.value === paymentConditionOption?.value);
          if (paymentConditionOption?.value && !isPaymentConditionOnArray) {
            options.push(paymentConditionOption);
          }
          setPaymentConditionOptions(options);

          const defaultPaymentConditionExists = result.data.find(value => value.id === defaultPaymentConditionId);

          if (defaultPaymentConditionExists) {
            setPaymentConditionOption(paymentConditionToOption(defaultPaymentConditionExists));
          }
        } else {
          setPaymentConditionOptions([]);
        }
      })
      .catch(error => {
        setPaymentConditionOptions([]);
        toastr.error(error.message);
      })
      .finally(() => {
        setLoadingPaymentConditions(false);
      });
  };

  const listPaymentConditions = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoadingPaymentConditions(true);
      const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc&filter=${filter}`;

      await listPaymentConditionService(url);
    }, timeout.current);
  };

  const listPaymentFormsService = async (url: string, defaultPaymentFormId: string = '') => {
    paymentFormService
      .listDynamically(url)
      .then(result => {
        if (result) {
          setPaymentForms(result.data);
          const options: IOption[] = result.data.map(paymentFormToOption);

          const isPaymentFormOnArray = options.find(opt => opt.value === paymentFormOption?.value);
          if (paymentFormOption?.value && !isPaymentFormOnArray) {
            options.push(paymentFormOption);
          }
          setPaymentFormOptions(options);

          const defaultPaymentFormExists = result.data.find(value => value.id === defaultPaymentFormId);

          if (defaultPaymentFormExists) {
            setPaymentFormOption(paymentFormToOption(defaultPaymentFormExists));
          }
        } else {
          setPaymentFormOptions([]);
        }
      })
      .catch(error => {
        setPaymentFormOptions([]);
        toastr.error(error.message);
      })
      .finally(() => {
        setLoadingPaymentForm(false);
      });
  };

  const listPaymentForms = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoadingPaymentForm(true);
      const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc&filter=${filter}`;

      await listPaymentFormsService(url);
    }, timeout.current);
  };

  const listSellers = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoadingSellers(true);
      const url = `perPage=1000&currentPage=1&orderBy=name&orderDirection=asc&filter=${filter}`;

      await sellerService
        .listDynamically(url)
        .then(result => {
          if (result) {
            setSellers(result.data);
            const options: IOption[] = result.data.map(sellerToOption);

            const isSellerOnArray = options.find(opt => opt.value === sellerOption?.value);
            if (sellerOption?.value && !isSellerOnArray) {
              options.push(sellerOption);
            }
            setSellerOptions(options);
          } else {
            setSellerOptions([]);
          }
        })
        .catch(error => {
          setSellerOptions([]);
          toastr.error(error.message);
        })
        .finally(() => {
          setLoadingSellers(false);
        });
    }, timeout.current);
  };

  useEffect(() => {
    listOrderTypes();

    const id = params.id;
    if (id && validateUuId(id)) {
      listOrder(id);
    }
  }, []);

  const isEditionDisabled = () => {
    return order?.orderStatus?.id !== 1 || loading || Number(order?.orderNumber) > 0;
  };

  return (
    <Container>
      {loading && <BackdropCustom />}

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Autocomplete
            disabled={isEditionDisabled()}
            options={customers}
            fullWidth
            value={customer}
            onChange={(_, newValue) => {
              setCustomer(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id || true;
            }}
            getOptionLabel={option => {
              if (option.isProspect) {
                return `(Prospect) ${option.corporateName} ${option.corporateDocument}`;
              }
              return `${option.code} ${option.corporateName} ${option.corporateDocument}`;
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Cliente"
                size="small"
                error={Boolean(errors?.customerId)}
                helperText={errors?.customerId}
                disabled={isEditionDisabled()}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Número do Pedido"
            size="small"
            value={`${order?.orderNumber || 0}`}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Situação"
            size="small"
            value={order?.orderStatus?.description || ''}
            disabled={true}
            fullWidth
          />
        </Grid>

        {!orderParams?.integrateHubSpot && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disabled={isEditionDisabled()}
              disablePortal
              options={orderTypes}
              fullWidth
              value={orderType}
              onChange={(_, newValue) => order && setOrderType(newValue || { description: '' })}
              isOptionEqualToValue={(option, value) => {
                return option?.description === value?.description || true;
              }}
              getOptionLabel={option => (option?.description ? `${option.description}` : '')}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo de Pedido"
                  size="small"
                  error={Boolean(errors?.orderTypeId)}
                  helperText={errors?.orderTypeId}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <LocalizationProviderMui dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePickerMui
              disabled
              slotProps={{ textField: { size: 'small' } }}
              label="Data de Emissão"
              value={orderDate}
            />
          </LocalizationProviderMui>
        </Grid>

        <Grid item xs={12} md={4}>
          <AsyncSearch
            options={sellerOptions}
            setOptions={setSellerOptions}
            option={sellerOption}
            setOption={handleSetSeller}
            asyncSearch={listSellers}
            loading={loadingSellers}
            error={Boolean(errors?.sellerId)}
            errorMessage={errors?.sellerId || null}
            label="Representante"
            disabled={isEditionDisabled()}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AsyncSearch
            options={carrierOptions}
            setOptions={setCarrierOptions}
            option={carrierOption}
            setOption={handleSetCarrier}
            asyncSearch={listCarriers}
            loading={loadingCarriers}
            error={Boolean(errors?.carrierId)}
            errorMessage={errors?.carrierId || null}
            label="Transportadora"
            disabled={isEditionDisabled()}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AsyncSearch
            options={redeliveryCarrierOptions}
            setOptions={setRedeliveryCarrierOptions}
            option={redeliveryCarrierOption}
            setOption={handleSetRedeliveryCarrier}
            asyncSearch={listRedeliveryCarriers}
            loading={loadingRedeliveryCarriers}
            error={Boolean(errors?.redeliveryCarrierId)}
            errorMessage={errors?.redeliveryCarrierId || null}
            label="Transportadora Redespacho"
            disabled={isEditionDisabled()}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AsyncSearch
            options={paymentConditionOptions}
            setOptions={setPaymentConditionOptions}
            option={paymentConditionOption}
            setOption={handleSetPaymentCondition}
            asyncSearch={listPaymentConditions}
            loading={loadingPaymentConditions}
            error={Boolean(errors?.paymentConditionId)}
            errorMessage={errors?.paymentConditionId || null}
            label="Condição de Pagamento"
            disabled={isEditionDisabled()}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AsyncSearch
            options={paymentFormOptions}
            setOptions={setPaymentFormOptions}
            option={paymentFormOption}
            setOption={handleSetPaymentForm}
            asyncSearch={listPaymentForms}
            loading={loadingPaymentForm}
            error={Boolean(errors?.paymentFormId)}
            errorMessage={errors?.paymentFormId || null}
            label="Forma de Pagamento"
            disabled={isEditionDisabled()}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Pedido do Cliente"
            size="small"
            value={customerOrderNumber || ''}
            onChange={e => setCustomerOrderNumber(e.target.value)}
            disabled={isEditionDisabled()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProviderMui dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePickerMui
              slotProps={{ textField: { size: 'small' } }}
              label="Data de Previsão"
              value={forecastDate}
              onChange={value => setForecastDate(dayjs(String(value)))}
              disabled={isEditionDisabled()}
            />
          </LocalizationProviderMui>
        </Grid>

        <Grid item xs={12} md={4}>
          <LocalizationProviderMui dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePickerMui
              slotProps={{ textField: { size: 'small' } }}
              label="Data Base"
              value={baseDate}
              onChange={value => setBaseDate(dayjs(String(value)))}
              disabled={isEditionDisabled()}
            />
          </LocalizationProviderMui>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: '1px' }}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Observações da Nota"
            fullWidth
            size="small"
            multiline
            rows={4}
            value={orderComments || ''}
            onChange={e => order && setOrderComments(e.target.value)}
            disabled={isEditionDisabled()}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: '1px' }}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Observações Internas"
            fullWidth
            size="small"
            multiline
            rows={4}
            value={orderInternalComments || ''}
            onChange={e => order && setOrderInternalComments(e.target.value)}
            disabled={isEditionDisabled()}
          />
        </Grid>
      </Grid>

      <Dialog
        open={calculatingOrder}
        maxWidth="md"
        PaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="primary" />
          <br />
          <span>O pedido está sendo recalculado. Isso pode demorar um pouco. Aguarde...</span>
        </DialogContent>
      </Dialog>
    </Container>
  );
});

export default OrderDataForm;
