import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import IPage from '../../interfaces/IPage';
import { ImageContainer } from './ProductList.styled';
import { useEffect, useRef, useState } from 'react';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import { useToastr } from '../../hooks/useToastr';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import { PageCard } from '../../components/page-card/PageCard';
import { IProduct } from '../../interfaces/IProduct';
import { useProductService } from '../../services/useProductService';
import noneImage from '../../assets/none-image.json';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const fields = [
  {
    field: 'sku',
    label: 'Cód. Produto',
  },
  {
    field: 'derivation',
    label: 'Derivação',
  },
  {
    field: 'description',
    label: 'Descrição',
  },
  {
    field: 'category',
    label: 'Categoria',
  },
];

interface IParams {
  perPage: number;
  currentPage: number;
  orderField: string;
  orderDirection: 'asc' | 'desc';
  field?: string;
  value?: string;
  precision?: string;
  delay?: number;
}

const ProductList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const { listAll } = useProductService();
  const toastr = useToastr();
  const timeout = useRef<any>(null);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('sku');
  const [loading, setLoading] = useState(false);

  const [filterField, setFilterField] = useState<string>('');
  const [filterPrecision, setFilterPrecision] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [delay, setDelay] = useState(0);

  const handlePagination = async (
    perPage: number,
    currentPage: number,
    orderField: string,
    orderDirection: 'asc' | 'desc',
  ) => {
    handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      field: filterField,
      value: filterValue,
      precision: filterPrecision,
      delay: 0,
    });
  };

  const handleListAll = async ({
    perPage,
    currentPage,
    orderField,
    orderDirection,
    field,
    value,
    precision,
    delay,
  }: IParams) => {
    let url = `perPage=${perPage}&currentPage=${
      currentPage + 1
    }&orderBy=${orderField}&orderDirection=${orderDirection}`;

    if (field && value && precision) {
      url = url + `&filterField=${field}&filterValue=${value}&precision=${precision}`;
    }

    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);

      await listAll(url)
        .then(response => {
          if (response?.data?.length > 0) {
            setProducts(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setProducts([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay || 0);
  };

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-br', { minimumFractionDigits: 2 }).format(value);
  };

  useEffect(() => {
    const field = filterField;
    const value = filterValue;
    const precision = filterPrecision;

    handleListAll({ perPage, currentPage, orderField, orderDirection, field, value, precision, delay });
  }, [filterField, filterPrecision, filterValue, delay]);

  const handleTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    setTotalRows(0);
    setCurrentPage(0);
    setDelay(500);
  };

  return (
    <>
      <TitleContainer>
        <h1>Produtos</h1>
      </TitleContainer>

      <PageCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-filter">Filtrar por</InputLabel>
              <Select
                label="Filtrar Por"
                value={`${filterField}`}
                onChange={e => {
                  setFilterField(`${e.target.value}`);
                }}
              >
                {fields.map(filter => {
                  return (
                    <MenuItem key={filter.field} value={filter.field}>
                      {filter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Precisão</InputLabel>
              <Select
                label="Precisão"
                value={`${filterPrecision}`}
                onChange={e => {
                  setFilterPrecision(`${e.target.value}`);
                }}
              >
                <MenuItem key={'equal'} value={'equal'}>
                  Igual
                </MenuItem>
                <MenuItem key={'containing'} value={'containing'}>
                  Contendo
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              label="Valor a filtrar"
              placeholder="Valor a filtrar"
              value={filterValue}
              onChange={handleTextFilterChange}
              size="small"
            />
          </Grid>
        </Grid>

        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="sku"
                  label="Cód. Produto"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="derivation"
                  label="Derivação"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="description"
                  label="Descrição"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="category_code"
                  label="Categoria"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="stockQuantity"
                  label="Estoque Disponível"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  disableOrder
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="image"
                  label="Imagem"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  disableOrder
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="isActive"
                  label="Ativo"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {products &&
                products.map(product => {
                  return (
                    <TableRow
                      key={product.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell size="small">{product.sku}</TableCell>
                      <TableCell size="small">{product.derivation}</TableCell>
                      <TableCell size="small">{product.description}</TableCell>
                      <TableCell size="small">
                        {product?.category ? `${product.category.code} - ${product.category.name}` : ''}
                      </TableCell>
                      <TableCell size="small">
                        {product?.stockQuantity && product?.stockQuantity > 0
                          ? formatCurrency(product.stockQuantity)
                          : '0,00'}
                      </TableCell>
                      <TableCell size="small">
                        <ImageContainer>
                          {product.image ? (
                            <img src={`data:image/png;base64,${product.image}`} alt={product.sku} />
                          ) : (
                            <img src={`data:image/png;base64,${noneImage.image}`} alt={product.sku} />
                          )}
                        </ImageContainer>
                      </TableCell>
                      <TableCell size="small">{product.isActive ? 'Sim' : 'Não'}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            handleRequest={handlePagination}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>
    </>
  );
};

export { ProductList };
