import styled from 'styled-components';

export const OrderFormContainer = styled.div`
  padding: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;

    .button-print {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 100%;
      }
    }

    .buttons-actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      button {
        width: 100%;
      }
    }
  }

  @media (max-width: 900px) {
    .buttons-actions {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: space-between;

      button {
        margin: 0 !important;
        margin-top: 16px !important;
      }
    }
  }
`;
