import React, { useEffect, useRef, useState } from 'react';
import IPage from '../../interfaces/IPage';
import OrderDataForm from './OrderDataForm';
import OrderItemList from './OrderItemList';
import OrderCustomerForm from './OrderCustomerForm';
import { ButtonContainer, OrderFormContainer } from './OrderForm.styled';
import { Button } from '../../components/button/Button';
import IOrderDataFormProps from './interfaces/IOrderDataFormProps';
import IOrderItemListProps from './interfaces/IOrderItemListProps';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrderService } from '../../services/useOrderService';
import { IOrder } from '../../interfaces/IOrder';
import { useToastr } from '../../hooks/useToastr';
import { IOrderItem } from '../../interfaces/IOrderItem';
import { useOrderItemService } from '../../services/useOrderItemService';
import IOrderCustomerFormProps from './interfaces/IOrderCustomerFormProps';
import { useAuth } from '../../hooks/auth';
import { useHubSpotService } from '../../services/useHubSpotService';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const OrderForm: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const { state, orderParams } = useAuth();

  const orderDataForm = useRef<IOrderDataFormProps>(null);
  const orderItemList = useRef<IOrderItemListProps>(null);
  const orderCustomerForm = useRef<IOrderCustomerFormProps>(null);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [, setItems] = useState<IOrderItem[]>([]);
  const [perPage] = useState(200);
  const [currentPage] = useState(1);
  const [orderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField] = useState<string>('sequence');
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [closeOrderDialogOpen, setCloseOrderDialogOpen] = useState(false);
  const [deleteItemsDialogOpen, setDeleteItemsDialogOpen] = useState(false);
  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
  const [errorClosingOrder, setErrorClosingOrder] = useState(false);
  const [calculatingOrder, setCalculatingOrder] = useState(false);

  const params = useParams();
  const orderService = useOrderService();
  const orderItemService = useOrderItemService();
  const toastr = useToastr();
  const hubSpotService = useHubSpotService();

  const navigate = useNavigate();

  const closeOrder = async (): Promise<void> => {
    if (order?.customer?.isProspect) {
      return;
    }
    setLoading(true);

    // recalcualr pedido aqui //
    setCalculatingOrder(true);
    const calculatedOrder = await orderService
      .calculateOrder(`${order?.id}`)
      .then(result => result)
      .catch(error => {
        toastr.error(`Ocorreu um erro ao recalcular o pedido: ${error.message}`);
      });

    const thereIsItemWithError = calculatedOrder?.items?.find(i => i.statusId !== 2);
    await orderItemList.current?.listItems();
    if (thereIsItemWithError) {
      setLoading(false);
      setCalculatingOrder(false);
      setErrorClosingOrder(true);
      return;
    }

    if (order) {
      await orderService
        .updateOrder(`${order.id}`, {
          id: order.id,
          orderNumber: order.orderNumber,
          branchOfficeId: (order.branchOffice?.id && order.branchOffice?.id) || '',
          orderTypeId: order.orderTypeId,
          customerId: order.customerId,
          sellerId: order.sellerId,
          orderStatusId: 2,
        })
        .then(async () => {
          handleSendDataToHubSpot('CLOSE');
          await listOrder();
          await orderDataForm.current?.listOrder(`${params.id}`);
          await orderItemList.current?.listOrder();

          toastr.success('Pedido fechado com sucesso.');
        })
        .catch(error => {
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
          setCalculatingOrder(false);
        });
    } else {
      setLoading(false);
      setCalculatingOrder(false);
    }
  };

  const handleOpenOrder = async (): Promise<void> => {
    const orderTemp = await listOrder();

    if (parseInt(`${orderTemp?.orderNumber}`) > 0) {
      await orderDataForm.current?.listOrder(`${params.id}`);
      setCancelOrderDialogOpen(true);
    } else {
      await openOrder();
    }
  };

  const openOrder = async () => {
    setLoading(true);
    setCancelOrderDialogOpen(false);
    await orderService
      .openOrder(`${params.id}`)
      .then(async () => {
        await listOrder();
        await orderDataForm.current?.listOrder(`${params.id}`);
        await orderItemList.current?.listOrder();

        toastr.success('Pedido reaberto  com sucesso.');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleListItems = async (): Promise<IOrderItem[] | void> => {
    return await orderItemService
      .listItemsByOrderId(
        `${params.id}`,
        `?perPage=${perPage}&currentPage=${currentPage}&orderBy=${orderField}&orderDirection=${orderDirection}`,
      )
      .then(response => {
        setItems(response.data);
        return response.data;
      })
      .catch(error => {});
  };

  const listOrder = async (): Promise<IOrder | void> => {
    return await orderService
      .listOrder(`${params.id}`, true)
      .then(order => {
        order && setOrder(order);
        return order;
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  const handleSendDataToHubSpot = (event: 'CLOSE' | 'SAVE') => {
    if (orderParams?.integrateHubSpot && params.id) {
      hubSpotService.updateAmountInHubSpot(params.id).catch(error => {
        toastr.error(error.message || 'Erro ao atualizar valor do pedido no HUBSpot. Contate o suporte.');
      });

      hubSpotService.updateDealStageInHubSpot(params.id, event).catch(error => {
        toastr.error(error.message || 'Erro ao atualizar estágio da negociação no HUBSpot. Contate o suporte.');
      });
    }
  };

  const handleSaveOrderData = async () => {
    // const localItems = await handleListItems();

    // const thereAreItemsWithErrors = localItems && localItems.filter(item => item.status?.id !== 2)?.length !== 0;

    // if (thereAreItemsWithErrors) {
    //   setLoading(false);
    //   toastr.error('Existem itens sendo calculados ou com erro de calculo. Verifique e tente novamente.');
    //   return;
    // }

    await orderDataForm.current?.save();
    await listOrder();

    handleSendDataToHubSpot('SAVE');
  };

  const handleItemsUpdated = async () => {
    orderCustomerForm.current?.listOrder(`${params.id}`);
    orderDataForm.current?.listOrder(`${params.id}`);
    orderItemList.current?.listItems();
    orderItemList.current?.listOrder();
    await listOrder();
  };

  const handleDeleteOrder = async (id: string | undefined) => {
    setLoading(true);

    if (id) {
      await orderService
        .deleteOrder(`${params.id}`)
        .then(() => {
          toastr.success('Pedido deletado com sucesso.');
          navigate('/orders-list');
        })
        .catch(error => {
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleRemoveItemsOrder = async (id: string | undefined) => {
    setLoading(true);

    if (id) {
      await orderItemService
        .removeAll(id)
        .then(async () => {
          toastr.success('Itens do pedido deletado com sucesso.');
          await orderItemList.current?.listItems();
          await orderItemList.current?.listOrder();
        })
        .catch(error => {
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isEditionDisabled = () => {
    return order?.orderStatus?.id !== 1 || loading || Number(order?.orderNumber) > 0;
  };
  const isCloseDisabled = () => {
    return (
      order?.orderStatus?.id !== 1 ||
      loading ||
      Number(order?.orderNumber) > 0 ||
      order?.customer?.isProspect ||
      order?.isDraft
    );
  };
  const isReopenDisabled = () => {
    return (
      (order?.orderStatus?.id !== 2 &&
        order?.orderStatus?.id !== 5 &&
        order?.orderStatus?.id !== 9 &&
        order?.orderStatus?.id !== 11) ||
      loading
    );
  };

  useEffect(() => {
    listOrder();
    handleListItems();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [params.id]);

  return (
    <OrderFormContainer>
      {loading && <BackdropCustom />}
      <OrderCustomerForm ref={orderCustomerForm} />
      <OrderDataForm ref={orderDataForm} handleItemsUpdated={handleItemsUpdated} />
      <ButtonContainer>
        <div className="button-print">
          <Button
            variant="contained"
            disabled={loading || order?.isDraft}
            onClick={() => {
              const token = btoa(state.tenantDomain);
              window.open(
                `${process.env.REACT_APP_BASE_FRONTEND_URL}/#/order/${params.id}/report/pdf?token=${token}`,
                '_blank',
              );
            }}
            style={{ minWidth: '130px' }}
          >
            Imprimir
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => navigate('/orders-list')}
            color="inherit"
            style={{ minWidth: '130px' }}
          >
            Voltar
          </Button>
        </div>
        <div className="buttons-actions">
          <Button
            variant="contained"
            onClick={() => setCloseOrderDialogOpen(!closeOrderDialogOpen)}
            disabled={isCloseDisabled()}
            color="success"
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
            title="Após a conclusão do pedido, ele será encaminhado automaticamente para ERP"
          >
            Fechar Pedido
          </Button>
          <Button
            variant="contained"
            onClick={handleOpenOrder}
            disabled={isReopenDisabled()}
            color="inherit"
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
            title="Caso o pedido já tenha integração ele será cancelado no ERP."
          >
            Reabrir Pedido
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeleteItemsDialogOpen(!deleteItemsDialogOpen)}
            color="error"
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
            disabled={isEditionDisabled()}
          >
            Remover Itens
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeleteDialogOpen(!deleteDialogOpen)}
            color="error"
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
            disabled={isEditionDisabled()}
          >
            Excluir Pedido
          </Button>
          <Button
            variant="contained"
            onClick={() => orderItemList.current?.addItem()}
            disabled={isEditionDisabled()}
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
          >
            Adicionar Itens
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveOrderData}
            disabled={isEditionDisabled()}
            style={{ minWidth: '130px' }}
            title="Salva as informações alteradas nos dados gerais do pedido"
          >
            Salvar
          </Button>
        </div>
      </ButtonContainer>
      <hr />
      <OrderItemList ref={orderItemList} itemsUpdated={handleItemsUpdated} />

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(!deleteDialogOpen);
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '-10px' }}>
            <strong style={{ fontSize: '16px' }}>Deseja realmente deletar o pedido?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginRight: '10px' }}>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setDeleteDialogOpen(!deleteDialogOpen);
            }}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              setDeleteDialogOpen(!deleteDialogOpen);
              await handleDeleteOrder(order?.id);
            }}
            autoFocus
            loading={loading}
            disabled={loading}
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteItemsDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(!deleteItemsDialogOpen);
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '-10px' }}>
            <strong style={{ fontSize: '16px' }}>Deseja realmente deletar os itens pedido?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginRight: '10px' }}>
          <Button
            color="error"
            onClick={() => {
              setDeleteItemsDialogOpen(!deleteItemsDialogOpen);
            }}
            disabled={loading}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              setDeleteItemsDialogOpen(!deleteItemsDialogOpen);
              await handleRemoveItemsOrder(order?.id);
            }}
            autoFocus
            loading={loading}
            disabled={loading}
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelOrderDialogOpen} maxWidth="md">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pedido já gerado no ERP. Caso confirme o pedido do ERP de número {order?.orderNumber} será cancelado.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setCancelOrderDialogOpen(false)} variant="contained">
            Cancelar
          </Button>
          <Button autoFocus onClick={openOrder} variant="contained">
            Reabir Pedido
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorClosingOrder} maxWidth="md">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Não foi possível fechar o pedido, pois existe um ou mais item com erro de cálculo. Verifique!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={() => setErrorClosingOrder(false)} variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={calculatingOrder}
        maxWidth="md"
        PaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="primary" />
          <br />
          <span>O pedido está sendo recalculado. Isso pode demorar um pouco. Aguarde...</span>
        </DialogContent>
      </Dialog>

      <Dialog
        open={closeOrderDialogOpen}
        onClose={() => {
          setCloseOrderDialogOpen(!closeOrderDialogOpen);
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '-10px' }}>
            <span style={{ fontSize: '16px' }}>
              <strong>Atenção:</strong> Todas as alterações não salvas serão perdidas.
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginRight: '10px' }}>
          <Button
            color="error"
            onClick={() => {
              setCloseOrderDialogOpen(!closeOrderDialogOpen);
            }}
            disabled={loading}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              setCloseOrderDialogOpen(!closeOrderDialogOpen);
              await closeOrder();
            }}
            autoFocus
            loading={loading}
            disabled={loading}
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </OrderFormContainer>
  );
};

export default OrderForm;
